import React, { useEffect, useState } from 'react';
import axios from 'axios';


function DataComponent() {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get('https://api.legisletter.org/api/data')
            .then(response => {
                console.log(response);
                setData(response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    return data ? <div>{data.message}</div> : "Loading...";
}



export default DataComponent;








